<template>
  <el-dialog
    :title="$t('reporting.email.email_recepients')"
    :visible.sync="dialogFormVisible"
    @close="close"
    class="emails-list-dialog"
  >
    <div>
      <p>
        {{ $t('reporting.email.description') }}
      </p>
    </div>
    <br />
    <el-form :model="form" ref="form" @submit.prevent.native="() => {}">
      <el-row :gutter="24">
        <el-col :span="15">
          <el-form-item
            prop="email"
            :rules="[
              {
                required: true,
                message: $t('reporting.email.required'),
                trigger: 'blur'
              },
              {
                type: 'email',
                message: $t('reporting.email.incorrect'),
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              type="email"
              prop="email"
              name="email"
              v-model.trim="form.email"
            />
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <base-button
            class="el-button-add"
            @click="addInList"
            type="primary"
            :round="false"
            :label="$t('general.label.add')"
          ></base-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="tableData">
      <el-table-column :min-width="200">
        <template slot-scope="{ row }">{{ row.email }}</template>
      </el-table-column>
      <el-table-column :width="100">
        <template slot-scope="{ row }">
          <base-button
            class="el-button-delete"
            @click.stop="handleDelete(row)"
            prepend-icon="ri-delete-bin-line"
          >
          </base-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: 'EmailsConfiguration',
  data () {
    return {
      form: {
        email: '',
        reportOspId: ''
      },
      tableData: [],
      dialogFormVisible: false
    }
  },
  methods: {
    show (data) {
      this.dialogFormVisible = true
      this.form.reportOspId = data.ospId
      this.fetchEmails()
    },
    close () {
      this.dialogFormVisible = false
      this.form.email = ''
    },
    async fetchEmails () {
      try {
        await this.$api.reporting.getListOfEmails(
          { reportOspId: this.form.reportOspId },
          {
            onSuccess: res => {
              this.setData(res)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
    },
    async addEmail (data) {
      try {
        await this.$api.reporting.addEmailToList(data, {
          onSuccess: () => this.fetchEmails()
        })
      } catch (e) {
        console.error(e)
      }
    },
    async deleteEmail (data) {
      try {
        await this.$api.reporting.removeEmailFromList(data, {
          onSuccess: () => this.fetchEmails()
        })
      } catch (e) {
        console.error(e)
      }
    },
    addInList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.addEmail(this.form)
          this.form.email = ''
        } else {
          return false
        }
      })
    },
    setData (data) {
      this.tableData = data
    },
    handleDelete (data) {
      this.deleteEmail({
        email: data.email,
        reportOspId: this.form.reportOspId
      })
    }
  }
}
</script>

<style lang="scss">
.emails-list-dialog {
  .el-dialog {
    width: 60%;
  }
  .el-dialog__body {
  word-break: initial !important;
  }
  .confirmation-info {
    width: 60%;
  }
  .el-button-delete {
    display: flex;
    i {
      font-size: 20px !important;
      margin: auto;
    }
  }

  .el-input__inner {
    height: 40px !important;
  }

  .el-form {
    margin: 15px 0;
  }
}
</style>
