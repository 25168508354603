var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"emails-list-dialog",attrs:{"title":_vm.$t('reporting.email.email_recepients'),"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close}},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('reporting.email.description'))+" ")])]),_c('br'),_c('el-form',{ref:"form",attrs:{"model":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":15}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
            {
              required: true,
              message: _vm.$t('reporting.email.required'),
              trigger: 'blur'
            },
            {
              type: 'email',
              message: _vm.$t('reporting.email.incorrect'),
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"type":"email","prop":"email","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1)],1),_c('el-col',{attrs:{"span":9}},[_c('base-button',{staticClass:"el-button-add",attrs:{"type":"primary","round":false,"label":_vm.$t('general.label.add')},on:{"click":_vm.addInList}})],1)],1)],1),_c('el-table',{attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"min-width":200},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(_vm._s(row.email))]}}])}),_c('el-table-column',{attrs:{"width":100},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('base-button',{staticClass:"el-button-delete",attrs:{"prepend-icon":"ri-delete-bin-line"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(row)}}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }