<template>
  <page-layout :title="$t('reporting.title')">
    <el-form
      ref="form"
      :key="key"
      :model="form"
      :inline="true"
      autocomplete="off"
      id="reports"
      label-position="top"
      v-loading="loading"
      @submit.prevent.native
    >
      <base-section style="padding-bottom: 24px" :key="filterKey">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              v-model="form.reportType"
              label="Report type"
              ref="reportType"
              prop="reportType"
              :items="reportTypes"
              style="min-width: 100%"
              name="reportType"
              :info="selectedReportType ? selectedReportType.description : ''"
              filterable
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-datepicker
              v-model="form.reportMonthFrom"
              :label="$t('general.label.start_date')"
              :picker-options="reportMonthFromOptions"
              :maxWidth="142"
              type="month"
              format="MM/yyyy"
            />
            <base-datepicker
              v-model="form.reportMonthTo"
              :label="$t('general.label.end_date')"
              :picker-options="reportMonthToOptions"
              :maxWidth="142"
              type="month"
              format="MM/yyyy"
            />
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              class="search"
              v-model.trim="form.search"
              name="search"
              auto-complete="off"
              :placeholder="$t('reporting.search_field_placeholder')"
            >
              <template #prependInput>
                <i class="ri-search-line"></i>
              </template>
            </base-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section class="table-wrapper">
        <el-table
          ref="table"
          :data.sync="tableData"
          :empty-text="$t('general.empty_table')"
          row-class-name="table-row"
          style="width: 100%"
          @sort-change="sortChange"
        >
          <el-table-column
            prop="icon"
            width="37"
          >
            <template>
              <i class="ri-file-chart-line"></i>
            </template>
          </el-table-column>
          <el-table-column
            prop="reportTimestamp"
            :label="$t('general.label.year')"
            width="80"
            sortable="sortColumn"
          >
            <template slot-scope="{ row }">
              {{ row.year }}
            </template>
          </el-table-column>
          <el-table-column
            prop="month"
            :label="$t('general.label.month')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="reportPeriod"
            :label="$t('general.label.period')"
            min-width="60"
            sortable="sortColumn"
          >
          </el-table-column>
          <template v-if="$auth.isMsp()">
            <el-table-column
              prop="mspExtId"
              :show-overflow-tooltip="true"
              :label="$t('general.label.partner')"
              min-width="160"
              sortable="sortColumn"
            >
            </el-table-column>
            <el-table-column
              prop="corporateAccountExtId"
              :show-overflow-tooltip="true"
              :label="$t('corporate_accounts_management.view_all_page.title')"
              min-width="160"
              sortable="sortColumn"
            >
            </el-table-column>
            <el-table-column
              prop="mrpExtId"
              :show-overflow-tooltip="true"
              :label="$t('partners_management.all_partners_page.mrp_label')"
              min-width="90"
              sortable="sortColumn"
            >
            </el-table-column>
          </template>
          <el-table-column
            prop="createdOn"
            :show-overflow-tooltip="true"
            :label="$t('user_management.view_app_users_page.budget.transactions.table.created_on')"
            width="111"
            sortable="sortColumn"
          >
            <template slot-scope="{ row }">
              {{ row.createdOnDate }}
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            class-name="actions-cell"
            width="175"
          >
            <template slot-scope="{ row }">
              <el-dropdown
                v-if="row.availableAsExcelFile"
                trigger="click"
                class="download-invoice"
              >
                <base-button
                  size="small"
                  append-icon="ri-arrow-down-s-line"
                  prepend-icon="ri-download-2-line"
                  :label="$t('general.label.download')"
                  :loading="row.ospId === currentDownloadingReport"
                  class="download-report-button"
                />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="download(row, 'xlsx')">
                    <i class="ri-download-2-line"></i>
                    {{ $t('general.label.download') }} Excel
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="download(row)">
                    <i class="ri-download-2-line"></i>
                    {{ $t('general.label.download') }} CSV
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <base-button
                v-else
                @click.stop="download(row)"
                style="padding-right: 25px"
                size="small"
                prepend-icon="ri-download-2-line"
                :label="$t('general.label.download')"
                :loading="row.ospId === currentDownloadingReport"
                class="download-report-button"
              >
              </base-button>
              <base-button
                @click.stop="handleShow(row)"
                style="margin-left: 10px"
                size="small"
                prepend-icon="ri-mail-line"
              >
              </base-button>
            </template>
          </el-table-column>
        </el-table>
      </base-section>
    </el-form>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
    <emails-configuration ref="emails" />
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce, get, orderBy } from 'lodash'
import CancelRequest from '@/mixins/CancelRequest'
import PageLayout from '@/layouts/PageLayout'
import ReportRepository from '@/app/repositories/ReportRepository'
import TableLoader from '@/mixins/TableLoader'
import ReportType from '@/app/models/ReportType'
import EmailsConfiguration from '@/components/Reports/EmailsConfiguration'
import { DateTime } from 'luxon'

const reportRepository = new ReportRepository()
const defaultSort = () => ({
  order: 'DESCENDING',
  prop: 'reportTimestamp'
})
export default {
  name: 'Reports',
  components: {
    PageLayout,
    EmailsConfiguration
  },
  mixins: [CancelRequest, TableLoader],
  data () {
    return {
      form: {
        reportType: null,
        reportMonthFrom: this.$date.local().minus({ month: 1 }).startOf('month').toISODate(),
        reportMonthTo: this.$date.local().toISODate(),
        search: ''
      },
      key: '',
      saving: false,
      saveDisabled: true,
      filterKey: '',
      tableData: [],
      isMounted: false,
      loading: false,
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: defaultSort()
      },
      currentDownloadingReport: null
    }
  },
  computed: {
    ...mapGetters({
      types: 'reporting/types'
    }),
    filter () {
      return {
        ...this.form,
        pagination: {
          ...this.pagination,
          sort: {
            sortOrders: [
              {
                direction: (this.pagination.sort.order || defaultSort().order).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        },
        reportMonthFrom: this.$dateFormat(this.form.reportMonthFrom, 'yyyyMM'),
        reportMonthTo: this.$dateFormat(this.form.reportMonthTo, 'yyyyMM')
      }
    },
    reportMonthFromOptions () {
      return {
        disabledDate: value => {
          return (
            value >
            (this.form.reportMonthTo
              ? this.$date.fromISO(this.form.reportMonthTo).startOf('day').toJSDate()
              : this.$date.local().endOf('day').toJSDate())
          )
        }
      }
    },
    reportMonthToOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    reportTypes () {
      return orderBy(
        this.types.map(type => {
          return {
            label: type.name,
            value: type.reportType
          }
        }),
        'label'
      )
    },
    hasPeriod () {
      return !!this.tableData.find(v => v.hasPeriod)
    },
    selectedReportType () {
      return ReportType.getTypeByReportType(this.form.reportType)
    }
  },
  watch: {
    'form.reportMonthTo' (v) {
      if (v < this.form.reportMonthFrom) {
        this.form.reportMonthFrom = v
        this.filterKey = this.$key()
      }
    },
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    form: {
      deep: true,
      handler: debounce(function () {
        this.isMounted && this.fetchData()
      }, 300)
    },
    'form.reportSearch': debounce(function () {
      this.fetchData()
    }, 700),
    'form.reportType' () {
      this.pagination.page = 0
    }
  },
  methods: {
    ...mapActions({
      getTypes: 'reporting/getTypes',
      clearTypes: 'reporting/clearTypes'
    }),
    fetchData (withLoading = true) {
      withLoading && this.TableLoader_show()
      return reportRepository.getByFilter(this.filter, {
        cancelToken: this.CancelRequest_token(),
        onSuccess: this.setData,
        onFinally: () => {
          withLoading && this.TableLoader_hide()
        }
      })
    },
    download (report, type = '') {
      this.currentDownloadingReport = report.ospId
      const onDownloadEnd = () => {
        this.currentDownloadingReport = null
      }
      report.download(type, { onDownloadEnd })
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.totalPages = totalPages
    },
    sortChange (sort) {
      if (sort.order) {
        this.pagination.sort = sort
      } else {
        this.pagination.sort = defaultSort()
      }
    },
    handleShow (data) {
      this.$refs.emails.show(data)
    }
  },
  async mounted () {
    this.clearTypes()
    this.loading = true
    if (!this.types || !this.types.length) {
      await this.getTypes()
    }
    this.key = this.$key()
    this.loading = false
    await this.fetchData()
    this.isMounted = true
  },
  beforeRouteEnter (to, from, next) {
    const form = {
      reportType: get(to.query, 'reportType', null),
      reportMonthFrom: get(
        to.query,
        'reportMonthFrom',
        DateTime.local().minus({ month: 1 }).startOf('month').toISODate()
      ),
      reportMonthTo: get(to.query, 'reportMonthTo', DateTime.local().toISODate())
    }
    next(vm => {
      vm.form = form
    })
  }
}
</script>

<style lang="scss">
.download-report-button {
  .el-icon-loading {
    top: 0px !important;
    font-size: 13px !important;
  }
}
</style>
